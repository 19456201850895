import React, { useContext, useCallback, useMemo } from 'react';
import {
  bool, node, string, arrayOf, oneOfType
} from 'prop-types';
import {
  useDataModel,
  params,
  arrayOf as arrayType,
  shape as shapeType,
  string as stringType,
  extend
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { AisleBayPodsContext } from './AisleBayPodsContext';
import { AisleBayPod } from './AisleBayPod';

const AisleBayPodsDataProvider = ({
  children,
  storeSkuIds,
  isSearchPage,
  hasInStoreFilter,
}) => {
  const { channel, store } = useContext(ExperienceContext);
  const { storeId } = store || {};
  const isMobile = channel === 'mobile';

  const isLocatedInStore = useCallback(() => {
    if (typeof window === 'undefined') {
      return false;
    }
    // eslint-disable-next-line no-undef
    const localizer = typeof THD_LOCALIZER_AUTO_INIT !== 'undefined' && THD_LOCALIZER_AUTO_INIT?.Localizer;
    const isUserInStore = localizer?.status?.isUserInStore();
    const hasGeofencedUserStore = localizer?.getUserStore();
    return (isUserInStore && hasGeofencedUserStore && hasGeofencedUserStore !== {});
  }, [hasInStoreFilter, storeSkuIds]);

  const showAisleBayInPods = isMobile && (hasInStoreFilter || (isLocatedInStore() && isSearchPage));
  const skipAisleBayPodsData = !(showAisleBayInPods && storeSkuIds?.length && storeId);

  const { data, error } = useDataModel('aislebay', {
    skip: skipAisleBayPodsData,
    ssr: false,
    variables: {
      storeId,
      storeSkuIds
    }
  });

  // passing getter through context instead of large array
  const getAisleBayForPod = useCallback(({ storeIdNum, storeSku }) => {
    if (!data) {
      return null;
    }
    const aisleBayForPod = (data?.aislebay?.storeSkus || []).find((aisleBayForSku) => {
      // aisle-bay-api returns padded zeros in 3-digit stores, e.g. 0121
      const storeNumber = `${Number(aisleBayForSku.storeNumber)}`;
      return (storeNumber === storeIdNum && aisleBayForSku.storeSkuId === storeSku);
    });
    return (aisleBayForPod || {}).aisleBayInfo;
  }, [data?.aislebay]);

  const showAisleBayPods = useMemo(() => {
    return (showAisleBayInPods && data?.aislebay?.storeSkus?.length > 0);
  }, [showAisleBayInPods, data?.aislebay]);

  const abpodsCntx = useMemo(() => {
    return { getAisleBayForPod, showAisleBayPods };
  }, [showAisleBayPods, getAisleBayForPod]);

  return (
    <AisleBayPodsContext.Provider value={abpodsCntx}>
      {children}
    </AisleBayPodsContext.Provider>
  );
};

AisleBayPodsDataProvider.displayName = 'AisleBayPodsDataProvider';

AisleBayPodsDataProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  storeSkuIds: arrayOf(string),
  isSearchPage: bool,
  hasInStoreFilter: bool,
};

AisleBayPodsDataProvider.defaultProps = {
  children: null,
  storeSkuIds: [],
  isSearchPage: false,
  hasInStoreFilter: false,
};

AisleBayPodsDataProvider.dataModel = extend({
  aislebay: params({
    storeId: stringType().isRequired(),
    storeSkuIds: arrayType(stringType().isRequired())
  }).shape({
    storeSkus: arrayType(shapeType({
      storeNumber: stringType(),
      storeSkuId: stringType(),
      aisleBayInfo: shapeType({
        aisle: stringType(),
        bay: stringType(),
        invLocDesc: stringType()
      })
    }))
  }),
}, AisleBayPod);

export { AisleBayPodsDataProvider };
